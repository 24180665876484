import { createAsync, useSubmission } from '@solidjs/router';
import { cachedGet, clientAction } from '@troon/api-client';
import {
	Errors,
	Form,
	Heading,
	HiddenFields,
	Input,
	Label,
	Select,
	Option,
	TextField,
	Field,
	Button,
	FieldDescription,
} from '@troon/ui';
import { createEffect, createMemo, createSignal, For, Show } from 'solid-js';
import { countryNames } from '@troon/countries';
import type { ApiResponse } from '@troon/api-client';
import type { RouteSectionProps } from '@solidjs/router';

export default function PersonalInfo(props: RouteSectionProps) {
	const user = createAsync(() => getUser({ path: { rewardsId: props.params.rewardsId! } }), { deferStream: true });

	const [showForm, setShowForm] = createSignal(false);

	return (
		<>
			<div class="flex flex-wrap items-center justify-between gap-x-4">
				<Heading as="h1" size="h2" class="grow">
					Contact Info
				</Heading>
				<Show when={!showForm()}>
					<Button appearance="secondary" class="grow-0" onClick={() => setShowForm(true)}>
						Edit
					</Button>
				</Show>
			</div>
			<div class="flex flex-col gap-8 rounded border border-neutral bg-white p-4 xl:p-8">
				<Show when={showForm()} fallback={<UserInfo user={user()} />}>
					<UpdateUserForm user={user()} onSubmit={() => setShowForm(false)} onCancel={() => setShowForm(false)} />
				</Show>
			</div>
		</>
	);
}

function UserInfo(props: { user: ApiResponse<'get', '/v0/operator/user/{rewardsId}'> | undefined }) {
	const address = createMemo(() => {
		const address = props.user?.address;
		if (!address) {
			return '';
		}
		return [address.street, address.street2, address.city, address.state, address.country, address.postalCode]
			.filter(Boolean)
			.join(', ');
	});

	return (
		<>
			<div class="flex flex-col gap-8">
				<div class="flex flex-1 flex-col gap-2">
					<span class="text-sm font-medium text-neutral-700">Phone Number</span>
					<span class="text-xl font-semibold">{props.user?.phoneNumber}</span>
				</div>

				<div class="flex flex-1 flex-col gap-2">
					<span class="text-sm font-medium text-neutral-700">Email</span>
					<span class="text-xl font-semibold">{props.user?.email}</span>
				</div>
			</div>

			<div class="flex flex-1 flex-col gap-2">
				<span class="text-sm font-medium text-neutral-700">Address</span>
				<span class="text-xl font-semibold">{address()}</span>
			</div>
		</>
	);
}

function UpdateUserForm(props: {
	user: ApiResponse<'get', '/v0/operator/user/{rewardsId}'> | undefined;
	onSubmit: () => void;
	onCancel: () => void;
}) {
	const submission = useSubmission(updateUser);

	createEffect(() => {
		if (submission.result) {
			submission.clear();
			props.onSubmit();
		}
	});

	return (
		<Form action={updateUser} autocomplete="off" class="flex max-w-3xl flex-col gap-8">
			<div class="flex flex-wrap items-center justify-stretch gap-4">
				<HiddenFields
					data={{
						rewardsId: props.user?.rewardsId,
					}}
				/>

				<TextField name="firstName" class="basis-full" required>
					<Label>First Name</Label>
					<Input autocomplete="off" value={props.user?.firstName ?? ''} />
				</TextField>

				<TextField name="lastName" class="basis-full" required>
					<Label>Last Name</Label>
					<Input autocomplete="off" value={props.user?.lastName ?? ''} />
				</TextField>

				<TextField name="phone" class="basis-full">
					<Label>Phone Number</Label>
					<Input autocomplete="off" value={props.user?.phoneNumber ?? ''} />
				</TextField>

				<TextField name="email" class="basis-full" required>
					<Label>Email</Label>
					<Input autocomplete="off" value={props.user?.email ?? ''} />
				</TextField>
			</div>

			<div class="flex flex-wrap items-center justify-stretch gap-4">
				<TextField name="street1" class="basis-full">
					<Label>Street</Label>
					<Input autocomplete="off" value={props.user?.address.street ?? ''} />
				</TextField>
				<TextField name="street2" class="basis-full">
					<Label>Street 2</Label>
					<Input autocomplete="off" value={props.user?.address.street2 ?? ''} />
				</TextField>
				<TextField name="city" class="grow">
					<Label>City</Label>
					<Input autocomplete="off" value={props.user?.address.city ?? ''} />
				</TextField>
				<TextField name="state" class="grow">
					<Label>State</Label>
					<Input autocomplete="off" value={props.user?.address.state ?? ''} />
				</TextField>
				<TextField name="postalCode" class="grow" required>
					<Label>Postal Code</Label>
					<Input autocomplete="off" value={props.user?.address.postalCode ?? ''} />
					<FieldDescription>
						If {props.user?.firstName} lives in a country without a postal code, please enter <code>00000</code> and
						enter their country. and region
					</FieldDescription>
				</TextField>
				<Field name="country" class="grow">
					<Label>Country</Label>
					<Select name="country" class="grow" value={props.user?.address.country ?? ''}>
						<Option disabled selected={!props.user?.address.country} class="hidden" />
						{/* Right now the user country is completely free form, so we can't guarantee that
						the users country matches any of the options in countryNames.
						ie. a user might have USA for their country which doesn't match United States
						We're just going to create a new array that has the users country as the first option
						and then all the other countries sorted alphabetically. */}
						<Show when={!countryNames.includes(props.user?.address.country ?? '')}>
							<Option>{props.user?.address.country}</Option>
						</Show>
						<For each={countryNames}>
							{(country) => (
								<Option selected={country === props.user?.address.country} value={country}>
									{country}
								</Option>
							)}
						</For>
					</Select>
				</Field>
			</div>

			<div class="flex grow-0 justify-start gap-4">
				<div>
					<Button appearance="primary" type="submit">
						Save
					</Button>
				</div>
				<div>
					<Button
						appearance="secondary"
						onClick={() => {
							submission.clear();
							props.onCancel();
						}}
					>
						Cancel
					</Button>
				</div>
			</div>

			<Errors />
		</Form>
	);
}

const getUser = cachedGet('/v0/operator/user/{rewardsId}');
const updateUser = clientAction(
	'POST',
	'/v0/operator/user/{rewardsId}',
	(data) => {
		return {
			params: { path: { rewardsId: data.get('rewardsId') as string } },
		};
	},
	{
		revalidate: ['/v0/operator/user/{rewardsId}'],
		transformer: (data) => {
			return {
				phone: (data.get('phone') as string) || null,
				street: (data.get('street1') as string) || null,
				street2: (data.get('street2') as string) || null,
				city: (data.get('city') as string) || null,
				state: (data.get('state') as string) || null,
				country: (data.get('country') as string) || null,
			};
		},
	},
);
